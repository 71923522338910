.materialGrande__img {
  height: auto;
  width: 100%;
}
.materialGrande__img_container {
  text-align: center;
  margin: 10px;
}

.materialGrande__descripcion {
  /* width: calc(100%-2%); */
  /* min-height: 600px; */
  display: grid;
  align-items: flex-start;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  /* padding: 2%; */
}

.materialGrande__descripcionContainer {
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 0%;
  padding-bottom: 0px;
}

.materialGrande__titulo {
  font-weight: 600;
}

.material__detalle_likeButton {
  /* font-weight: 600 !important; */
  color: gray !important;
  font-size: 16px !important;
}

.material__detalle_likeIcon {
  font-size: 30px !important;
  /* margin-right: 10px; */
}

.material__detalle__texto_secundario.autor {
  font-weight: 600 !important;
}
.material__detalle__userIcon {
  font-size: 50px !important;
}

.oa__titulo {
  font-weight: 600;
}

.oa__selector {
  font-size: 25px !important;
  width: 100%;
}

.boton__descargar {
  margin-top: 10px;
}

.boton__descargarContainer {
  align-self: flex-end;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 15px;
}

.boton__subirContainerA {
  display: flex;
  justify-content: flex-end;
  padding-right: calc(2%);
  margin-bottom: 20px;
  margin-top: 10px;
}
.boton__subirMaterialA {
  height: 35px;
  width: 120px;
}
.boton__subirMaterialIconA {
  padding: 2%;
}

@media (max-width: 500px) {
  .materialGrande__img {
    width: 80%;
  }
  .materialGrande__img_container {
    width: 80%;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .materialGrande__img {
    width: 50%;
  }
  .materialGrande__img_container {
    width: 50%;
  }
}

@media (min-width: 900px) {
  .boton__subirMaterialA {
    z-index: 1;
    transform: translateY(-60px);
  }

  .boton__subirContainerA {
    height: 0;
    margin: 0;
  }
}
