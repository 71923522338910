/* Cards de material */

.material__TituloContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px 5px 15px;
}
.material__Titulo {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  vertical-align: middle;
  text-align: left;
  font-size: 15px;
  font-weight: 600;
}
.material__ImagePreview {
  width: 100%;
  height: 215px;
  background-color: #f1f1f1;
}
.material__ImageContainer {
  height: 215px;
  background-color: #f1f1f1;
}
.material__FooterContainer {
  width: 100%;
  height: 50px;
  border-radius: 0px 0px 5px 5px;
}
.material__IconContainer {
  display: flex;
  justify-content: center;
}
.material__AutorContainer {
  display: flex;
  align-items: center;
  vertical-align: middle;
  text-align: left;
  font-size: 15px;
}
.material__IconAutorContainer {
  height: 25px;
  padding: 5px;
}
.material__LikeIcon {
  display: flex;
  justify-content: center;
  color: #677bff;
}
.material__LikeIconLabel {
  display: flex;
  align-items: center;
  vertical-align: middle;
  text-align: left;
  font-size: 15px;
  color: #677bff;
}
.material__ChatIcon {
  display: flex;
  justify-content: center;
  color: #ff8168;
}
.material__ChatIconLabel {
  display: flex;
  align-items: center;
  vertical-align: middle;
  text-align: left;
  font-size: 15px;
  color: #ff8168;
}
.material__DocExtensionIconTop {
  height: 20px;
  width: 20px;
  z-index: 1;
  transform: translateY(10px);
  margin-left: 12px;
  background-color: transparent;
  border-radius: 2px;
}
.material__SpaceBetween {
  display: flex;
  justify-content: space-between;
}
.material__HeaderContainer {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  text-align: left;
  padding-left: 15px;
  padding-right: 15px;
}
.material__HeaderText {
  font-size: 12px;
  color: white;
}
.material__TipoContainer {
  background-color: #6a6666;
  border-radius: 6px;
  width: fit-content;
  height: fit-content;
  padding: 3px;
  z-index: 1;
  transform: translateY(10px);
}
.material__TipoText {
  color: white;
  font-size: 10px;
}
.material__Invisible {
  display: flex;
  height: 0px;
  width: 100%;
  padding-left: 15px;
}

.skeleton__docente {
  width: 250px;
  height: 400px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  box-shadow: 5px 5px 4px -1px rgb(0 0 0 / 50%);
  border-radius: 5px;
  border: 2px solid #dbdbdb;
  background-size: cover;
}
