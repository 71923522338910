.fororesultados__resultadoscontainer {
  display: grid;
  grid-gap: 20px 12px;
  justify-content: space-between;
  align-items: start;
}


.boton__subirContainer_categoria {
  display: flex;
  justify-content: flex-end;
  padding-left: calc(5% + 20px);
  padding-right: calc(5% + 20px);
}

.boton__agregar_categoria {
  height: 35px;
  width: 150px;
  z-index: 1;
  transform: translateY(-30px);
  margin: 10px 0 0 20px !important;
}

.categoria__skeleton {
  height: 200px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.03) !important;
  border-radius: 5px;
}

.foro_profile_comment {
  width: 4rem;
  height: auto;
}

@media (min-width: 0px) and (max-width: 900px) {
  .fororesultados__resultadoscontainer {
    grid-template-columns: repeat(auto-fill, 100%);
  }
}

@media (min-width: 900px) and (max-width: 1300px) {
  .fororesultados__resultadoscontainer {
    grid-template-columns: repeat(auto-fill, calc(50% - 6px));
  }
}

@media (min-width: 1300px) and (max-width: 1700px) {
  .fororesultados__resultadoscontainer {
    grid-template-columns: repeat(auto-fill, calc(33.3% - 12px));
  }
}

@media (min-width: 1700px) {
  .fororesultados__resultadoscontainer {
    grid-template-columns: repeat(auto-fill, calc(25% - 18px));
  }
}

@media (max-width: 1000px) {
  .boton__agregar_categoria {
    transform: none;
  }
}

@media (max-width: 400px) {
  .boton__subirContainer_categoria {
    padding: 0;
  }
}