.Common__container {
  width: 100%;
  height: fit-content;
  padding-right: 0;
  min-width: 100%;
  display: block;
  box-sizing: border-box;
}

.Component {
  margin: 10px 0 30px 0;
}
