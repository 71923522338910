/* LOGIN */
.root__login {
  display: flex;
  width: inherit;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.card__login {
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 30px;
  width: 30%;
  height: inherit;
  padding: 10px 0 10px 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23) !important;
  background: rgba(38, 126, 191, 0.6) !important;
  color: white !important;
}

.image_login_linkki {
  width: 300px;
}

.formGroup {
  margin: 10px;
  justify-content: center;
  flex-wrap: nowrap;
}

.button {
  margin: 10px;
}

.errorForm {
  color: red;
}

.title__login {
  font-weight: bold;
  color: white;
}

.css-1m0pprt-MuiInputBase-root-MuiOutlinedInput-root {
  background-color: white;
}

@media (max-width: 480px) {
  .card__login {
    width: 100%;
  }

  .image_login_linkki {
    width: 70%;
  }
}

@media(max-width: 880px) {
  .image_login_linkki {
    width: 100%;
  }
}

/* RESET PASSWORD */

/* REGISTER */

.Registration__form {
  max-width: 500px;
}

/* IMAGE TRANSITION */

.login_image_transition img {
  position: absolute;
  top: 0;
  -webkit-animation-name: fade;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 16s;
  animation-name: fade;
  animation-iteration-count: infinite;
  animation-duration: 16s;
  filter: brightness(50%);
}

@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  33% {
    opacity: 1;
  }

  53% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  33% {
    opacity: 1;
  }

  53% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

#image_transition_1 {
  background-color: white;
}

#image_transition_2 {
  -webkit-animation-delay: -12s;
  background-color: white;
}

#image_transition_3 {
  -webkit-animation-delay: -8s;
  background-color: white;
}

#image_transition_4 {
  -webkit-animation-delay: -4s;
  background-color: white;
}

.image_transition {
  position: relative;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.white-link {
  color: white;
}