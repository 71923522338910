.Head__textContainer {
  padding-top: 0.5%;
  padding-left: 5%;
  display: block;
  text-align: left;
}
.Head__padding {
  padding-left: 20px;
  padding-top: 10px;
}
.Head__titulo {
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  letter-spacing: 2.4px;
}
.Head__subtitulo {
  font-style: normal;
  font-size: 20px;
  color: #808080;
  letter-spacing: 1.8px;
}

@media (max-width: 400px) {
  .Head__titulo {
    font-size: 20px;
  }
  .Head__subtitulo {
    font-size: 15px;
  }
}
