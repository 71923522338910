.Caterogia_Conversaciones__Container {
  padding-right: calc(5% + 20px);
  padding-left: calc(5% + 20px);
  width: 100%;
  height: fit-content;
  min-width: 100%;
  display: block;
  box-sizing: border-box;
}

.Categoria_Conversaciones_buscador__container {
  text-align: left;
  min-height: 70px;
  background-color: transparent;
  display: flex;
  width: 100%;
}

.Caterogia_Conversaciones__fecha_container{
  min-height: 80px;
}

.Caterogia_Conversaciones__keyboard_date_picker{
  width: 100%;
  height: 50px;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}


.Caterogia_Conversaciones__boton_buscar {
  background-color: #2d9bf0 !important;
  margin: 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

}

.Categoria_Conversaciones_text_field__container {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}


.Caterogia_Conversaciones__Header {
  text-align: left;
  align-items: flex-start;
}

.Caterogia_Conversaciones__Descripcion {
  text-align: justify;
}

.Caterogia_Conversaciones__nueva_conversacion {
  width: 100%;
  background-color: #2d9bf0 !important;
}

.Caterogia_Conversaciones__imagen {
  width: 100%;
}

.Caterogia_Conversaciones__imagen_Container {
  width: 100%;
}

@media (min-width: 500px) and (max-width: 900px) {
  .Caterogia_Conversaciones__icons_Container {
    grid-template-columns: repeat(auto-fill, calc(33%));
  }
}

@media (max-width: 500px) {
  .Caterogia_Conversaciones__imagen {
    width: 100%;
  }
}
