.root {
  margin: 0;
  padding: 0;
  max-width: 100%;
}
.container {
  width: 95%;
  padding: 6px 10px;
  margin: auto;
  margin-top: 10px;
}
.form {
  margin: 50px 0;
}
.label {
  width: 100px;
}
.dropzone {
  width: 70%;
  margin: 30px auto;
}
.buttonContainer {
  display: flex;
  justify-content: space-around;
  margin: 20px;
}

.input {
  width: 47% !important;
}
@media (max-width: 700px) {
  .input {
    width: 100%;
  }
}
