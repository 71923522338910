.post__comentario__btns {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: -webkit-fill-available;
  margin-left: 0px;
  /* flex-grow: 1; */
}

.fecha_comentario {
  display: flex;
  padding: 0;
  margin: 0px;
  font-size: 15px;
  font-weight: 450;
}


/* @media (min-width: 0px) and (max-width: 350px) {

} */