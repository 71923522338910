.menuButton {
  margin-right: 0;
}

.link {
  color: white;
  text-decoration: none;
  margin: 10px !important;
}

.link:hover {
  color: #eeeeee;
  cursor: pointer;
}

.linksContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.logo {
  height: 40px;
}

@media (max-width: 600px) {
  .link.profile {
    display: none;
  }
}