/* header */
.capsula__HeaderContainer {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  text-align: left;
  padding-left: 15px;
  padding-right: 15px;
}
.capsula__HeaderText {
  font-size: 12px;
  color: white;
}

/* Preview */
.capsula__ImagePreview {
  width: 100%;
  height: 168.75px;
  background-color: #181818;
}
.capsula__ImagePreview.processing_icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Metodologia */
.capsula__TipoContainer {
  background-color: #6a6666;
  border-radius: 6px;
  width: fit-content;
  height: fit-content;
  padding: 3px;
  z-index: 1;
  transform: translateY(-30px);
}
.capsula__TipoText {
  color: white;
  font-size: 12px;
}
.capsula__Invisible {
  display: flex;
  height: 0px;
  width: 100%;
  padding-left: 15px;
}

/* Footer */
.capsula__FooterContainer {
  width: 100%;
  height: calc(110.25px + 0px);
  border-radius: 0px 0px 5px 5px;
}

/* Titulo */
.capsula__TituloContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px 5px 15px;
}
.capsula__Titulo {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  vertical-align: middle;
  text-align: left;
  font-size: 15px;
  font-weight: 700;
}

/* Usuario */
.capsula__AutorLikeVisita__Container {
  height: 46.25px;
  padding: 0px 15px 0px 15px;
}

/* Autor y fecha */
.capsula__IconContainer {
  display: flex;
  justify-content: center;
  padding-left: 0 !important;
}
.capsula__AutorContainer {
  display: flex;
  align-items: center;
  vertical-align: middle;
  text-align: left;
  font-size: 12px;
  flex-wrap: wrap;
}
.capsula__NombreAutor {
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
  font-size: 12px;
  font-weight: 650;
}

.capsula__VisitasFecha {
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
  font-size: 11px;
}

/* Like y comentarios */
.capsula__SpaceBetween {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.capsula__LikeIcon {
  display: flex;
  justify-content: center;
  color: #677bff;
  margin: auto !important;
  flex-basis: auto !important;
}
.capsula__LikeIconLabel {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 15px;
  color: #677bff;
  flex-basis: auto !important;
}
.capsula__ChatIcon {
  display: flex;
  justify-content: center;
  color: #ff8168;
  margin: auto !important;
  flex-basis: auto !important;
}
.capsula__ChatIconLabel {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 15px;
  color: #ff8168;
  flex-basis: auto;
  flex-basis: auto !important;
}

.MuiAlert-icon {
  display: none !important;
}

.MuiCardActionArea-root {
  height: -webkit-fill-available;
}

.skeleton__capsula {
  width: 300px;
  height: calc(25px + 168.75px + 110.25px + 0px);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-size: cover;
  margin-bottom: 15px;
  margin-right: 15px;
  cursor: pointer;
}

.capsula__processing_icon {
  color: #e8e8e8;
  font-size: 35px !important;
}

@media (max-width: 400px) {
  .capsula {
    height: fit-content;
    padding-bottom: 0px;
  }
}

.usuario_icon {
  object-fit: cover;
  object-position: center;
  height: 2em;
  border-radius: 50%;
}