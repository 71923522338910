.root {
margin: 0;
padding-left: calc(5% + 20px);
padding-right: calc(5% + 20px);
max-width: 100%;
}

.container {
margin: auto;
margin-top: 30px;
background: #f4f6f7;
}
.form_Capsulas {
margin: 50px 0;
}

.label {
width: 100px;
}
.dropzone_capsulas {
width: 70%;
margin: 30px auto;
}
.buttonContainer {
display: flex;
justify-content: space-around;
margin: 20px;
}

.input {
width: 47%;
}
.error {
color: red;
}
@media (max-width: 700px) {
.input {
    width: 100%;
}
}
