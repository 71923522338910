.Landing__content {
  display: grid;
  grid-template-columns: repeat(auto-fill, 260px);
  grid-gap: 10px;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 1%;
}
.Item__container {
  width: 240px;
  height: 220px;
  border: 20px;
  margin: 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.Item__iconBoxContainer {
  display: inline-block;
}
.Item__iconBox {
  width: 70px;
  height: 70px;
  background-color: #ffffff;
  z-index: 1;
  transform: translateY(-70px);
  border-radius: 18px;
  box-shadow: 5px 5px 4px -1px rgb(0 0 0 / 50%);
  align-items: center;
  display: flex;
}
.Item__titulo {
  width: 100%;
  background-color: #ffffff;
  transform: translateY(-65px);
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
}
.Item__texto {
  width: 100%;
  height: 30%;
  background-color: #ffffff;
  transform: translateY(-65px);
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  align-items: center;
}
.Item__textPadding {
  padding: 0 20px;
}

@media (max-width: 400px) {
  .Item__container {
    width: fit-content;
    height: 220px;
  }
}
