.Conversacion__container {
    padding-right: calc(5% + 20px);
    padding-left: calc(5% + 20px);
    width: 100%;
    height: fit-content;
    min-width: 100%;
    display: block;
    box-sizing: border-box;
    margin-top: 20px;
}

.publicacionHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    /* boxShadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important; */
    font-size: 16;
    font-weight: 600;
    background-color: white;
    min-height: fit-content
}
.publicacionTituloContainer  {
    width: calc(100% - 20px);
    height: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
    font-size: 20;
    font-weight: 700;
    min-height: 54px;
}
.publicacionContenidoContainer {
    width: calc(100% - 20px);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    /* //boxShadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important; */
    font-size: 16;
    font-weight: 400;
    background-color: white;
    min-height: fit-content
}
.tituloContenidoContainer  {
    width: calc(100%);
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 1;
    align-items: stretch;
    /* //alignContent: center; */
    justify-content: center
}
.publicacionRightPadding {
    padding-right: 20px;
}

.comentar__titulo {
    font-size: 18px;
    letter-spacing: 0.7px;
    font-weight: bolder;
    padding-left: 30px;
}


/* ////////////////// */

.autor_fecha_container_post {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
}

.autor_fecha_subcontainer_post {
    height: 100%;
    width:fit-content;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.post__NombreAutor {
    /* display: flex; */
    align-items: center;
    text-align: left;
    /* width: 100%; */
    font-size: 18px;
    font-weight: 550;
    padding-left: 10px;
    max-width: 240px;
}

.post__VisitasFecha {
    display: flex;
    align-items: center;
    text-align: left;
    width: 240px;
    font-size: 15px;
    font-weight: 450;
    padding-left: 10px;
    flex-wrap: wrap;
    margin-top: 5px;
}

.ver_perfil_button_post {
    height: 25px;
    width: fit-content !important;
    min-width: fit-content !important;
    padding: 10px !important;
    /* display: flex !important; */
    /* flex-grow: 1 !important; */
    /* margin-left: 5px !important; */
    /* max-width:100% !important; // Para "Ver perfil"
    min-width:80px !important; */
    /* max-width:35.8px !important; */
    max-width: fit-content !important;
    min-width:0px !important;
}

.post__icons_container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-grow: 1;
    max-width: 400px;
}

.post__IconContainer {
    display: flex;
    justify-content: center;
    min-width: fit-content;
}

.post__AutorContainer {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 12px;
    flex-wrap: wrap;
    justify-content: space-between;
    /* width: fit-content; */
    /* flex-grow: 1; */
}

.post__title {
    display: flex;
    align-items: center;
    text-align: left;
    width: 100%;
    font-size: 32px;
    font-weight: 700;
    color: rgba(41, 41, 41, 1);
    letter-spacing: -0.016em;
    line-height: 40px;
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
    /* padding-left: 10px;
    padding-right: 10px; */
}

.post__content {
    display: block; /* Para que los <p> usen todo el width */
    align-items: center;
    text-align: left;
    width: 100%;
    font-size: 20px;
    font-weight: 400;
    background-color: white;
    min-height: fit-content;
    color: rgba(41, 41, 41, 1);
    word-break: break-word;
    letter-spacing: -0.003em;
    font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;
}

.post__content p{
    margin: 5px 0px
}

.head_post_container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width:100%
}
/*//////////////////*/
/*  New Comentario  */
.autor_fecha_container_nuevocomentario {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-grow: 1;
}

.autor_fecha_subcontainer_nuevocomentario {
    height: 100%;
    width: -webkit-fill-available;
    display:flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.post__nuevoComentario {
    display: flex;
    /* flex-grow: 1; */
    align-items: center;
    text-align: left;
    width: 100% !important;
    font-size: 15px;
    font-weight: 450;
    padding-left: 10px;
    flex-wrap: wrap;
    margin-top: 5px;
}

.post__nuevoComentarioAutor {
    /* display: flex; */
    /* flex-grow: 1; */
    align-items: center;
    text-align: left;
    /* width: 100% !important; */
    max-width: 100% !important;
    font-size: 18px;
    font-weight: 550;
    padding-left: 10px;
    /* flex-wrap: wrap; */
    margin-top: 5px;
}

.post__nuevoComentarioAutorComentario__Container {
    /* display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start; */
    width: 100% !important;
    /* flex-grow: 1; */
}

.post__nuevoComentarioButton__Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: -webkit-fill-available;
    /* flex-grow: 1; */
}

/*//////////////////////////*/
/* Responsive */

@media (min-width: 0px) and (max-width: 900px) {
    .head_post_container {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .post__NombreAutor {
        /* display: flex; */
        align-items: center;
        text-align: left;
        /* width: 100%; */
        font-size: 18px;
        font-weight: 550;
        padding-left: 10px;
        max-width: 100%;
    }
    
    .post__VisitasFecha {
        display: flex;
        align-items: center;
        text-align: left;
        width: 100%;
        font-size: 15px;
        font-weight: 450;
        padding-left: 10px;
        flex-wrap: wrap;
        margin-top: 5px;
    }

    .ver_perfil_button_post {
        /* max-width:78.73px !important; // Para Ver perfil*/
        /* max-width:32px !important; */
    }

    .post__icons_container {
        max-width: 100%;
    }
}

@media (min-width: 960px) {
    .hide_above_sm {
        display: none;
    }
}