.center {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 30px 0;
}
.capsulas {
  display: grid !important;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-gap: 12px 12px;
  justify-content: space-between;
}
.boton__subirContainer {
  display: flex;
  justify-content: flex-end;
  padding-left: calc(5% + 20px);
  padding-right: calc(5% + 20px);
  height: 0px;
}
.Boton__subirCapsula {
  height: 35px;
  width: 120px;
  z-index: 1;
  transform: translateY(-30px);
}
.Boton__subirCapsulaIcon {
  padding: 2%;
}
.Buscador__titulo {
  width: auto;
  font-size: 15px;
  letter-spacing: 0.7px;
}
.Capsulas__container__padding {
  text-align: left;
  width: auto;
  height: fit-content;
  background-color: transparent;
  padding-left: calc(5% + 20px);
  padding-right: calc(5% + 20px);
  display: flex;
  align-items: left;
}
.Capsulas__Resultados__padding {
  text-align: left;
  width: auto;
  height: fit-content;
  background-color: transparent;
  padding-left: calc(5% + 20px);
  padding-right: calc(5% + 20px);
  padding-top: 15px;
  display: flex;
  flex-direction: column;
}
.Buscador__divider {
  margin-top: 20px;
  margin-bottom: 20px;
}
.Buscador__input {
  width: 100%;
}

.paginamiento {
  align-self: center;
}

.Capsulas__procesando__acordeon {
  background-color: #e8e8e8 !important;
  margin-bottom: 15px !important;
}

@media (max-width: 760px) {
  .Boton__subirCapsula {
    transform: none;
  }
}

@media (max-width: 400px) {
  .boton__subirContainer {
    padding: 0;
  }
}
