/* Barra superior o header*/
.manual__headerContainer {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* //justify-content: center; */
  vertical-align: middle;
  text-align: left;
  padding-left: 15px;
  padding-right: 15px;
}

.manual__headerText {
  font-size: 12px;
  color: white;
}

/* Miniatura */
.manual__imageSection {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.manual__image {
  height: 100%;
  width: -webkit-fill-available;
}

/* Titulo */
.manual__titulo {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 15px;
  /* 15px */
  font-weight: 600;
  padding: 0px 5% 0px 5%;
}

/* Resumen */
.manual__textInfo {
  font-size: 12px;
  text-align: justify;
}

/* Autor, visitas, likes, comentarios, pdf*/

.manual__autorLikeVisita__container {
  height: 30%;
  /* background-color: blue; */
  padding: 15px 5% 15px 5%;
}

/* Botón resumen */

.botonResumenContainer {
  height: calc(45% - 30px - 10px);
  display: flex;
  justify-content: center;
  align-content: center;
}

.manual__button {
  width: 90%;
  height: 70%;
  padding-left: 5%;
  padding-right: 5%;
  font-size: 12px !important;
}

.manual__width {
  max-width: 100%;
  min-height: fit-content;
  max-height: 720px;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;
  border-radius: 5px;
  background-size: cover;
  margin: 0px !important;
  cursor: pointer;
}

.manual__skeleton {
  height: 200px;
}