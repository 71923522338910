  .Caterogia_Conversaciones__Header {
    text-align: left;
    align-items: flex-start;
  }

  .Caterogia_Conversaciones__Descripcion {
    text-align: justify;
  }

  .Caterogia_Conversaciones__nueva_conversacion {
    width: 100%;
    background-color: #EAC17E !important;
  }

  /* 16:9 img */
  .Caterogia_Conversaciones__imagen { 
    /* width: auto; */
    width: 226px;
    height: 127px;
  }

  .Caterogia_Conversaciones__imagen_Container {
    width: 226px;
    height: 127px;
  }

  @media (min-width: 500px) and (max-width: 900px) {
    .Caterogia_Conversaciones__icons_Container {
      grid-template-columns: repeat(auto-fill, calc(33%));
    }
  }

  @media (max-width: 500px) {
    .Caterogia_Conversaciones__imagen {
      width: 100%;
    }
  }

.CategoriaHead__ImageSubtitle {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
}
