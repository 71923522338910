.Page_Container {
  width: calc(100% - 58px);
  padding-left: 58px;
}

@media (max-width: 480px) {
  .Page_Container {
    width: 100%;
    padding-left: 0;
  }
}