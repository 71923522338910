.Comentario__Container {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px 30px;
}

.Comentario__imagen {
  width: 60px;
}

.Comentario__icon {
  font-size: 43px !important;
}

.Comentario__info {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Comentario__usuario {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-right: 10px;
}

.Comentario__fecha {
  font-size: 14px;
  font-weight: 200;
  margin-bottom: 10px;
}

.Comentario__contenido {
  font-size: 14px;
}

.Comentario__botones {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.Comentario__boton_responder {
  margin: 0;
  padding: 0;
}

.Comentario__boton_respuestas {
  color: #065fd4 !important;
}

.Comentario__botones > .comentar {
  margin-right: 20px;
}
@media (max-width: 500px) {
  .Comentario__Container {
    padding: 10px 0;
  }
}
