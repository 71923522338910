.root {
  margin: 0;
  padding-left: calc(5% + 20px);
  padding-right: calc(5% + 20px);
  max-width: 100%;
  text-align: left;
}

.Manual__detalle_titulo {
  font-weight: 600;
}

.Manual__detalle_img {
  height: auto;
  width: 100%;
}
.Manual__detalle_img_container {
  text-align: center;
}

.Manual__detalle_descripcion {
  text-align: justify;
}

.Manual__detalle_descargarContainer {
  margin-top: 10px !important;
}

.boton__subirContainerD {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-top: 10px;
}

.boton__subirMaterialD {
  height: 35px;
  width: 120px;
}

.boton__subirMaterialIconD {
  padding: 2%;
}

@media (max-width: 500px) {
  .Manual__detalle_img {
    width: 80%;
  }
  .Manual__detalle_img_container {
    width: 80%;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .Manual__detalle_img {
    width: 50%;
  }
  .Manual__detalle_img_container {
    width: 50%;
  }
}

@media (min-width: 900px) {
  .boton__subirMaterialD {
    z-index: 1;
    transform: translateY(-60px);
  }

  .boton__subirContainerD {
    height: 0;
    margin: 0;
  }
}
