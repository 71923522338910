/* .ConversacionItem__container {
  width: 100%;
  background-color: transparent;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 3%) !important;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 10px 0px;
  text-align: left;
  border: 1px solid rgb(232, 232, 232);
} */

.ConversacionItem__container:hover {
  cursor: pointer;
  border: 1px solid gray;
}

.ConversacionItem__titulo {
  font-weight: bold;
}

.ConversacionItem__informacion {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ConversacionItem__informacion_estadisticas {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;
  margin: 0 5px;
  min-width: 60px;
}

.ConversacionItem__informacion_estadisticas_icon {
  margin-right: 10px;
}

.ConversacionItem__autor {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center
}

.ConversacionItem__autor__text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
}

.ConversacionItem__autor_icon {
  margin-right: 10px;
}
