.ForoIcon__cantidad {
  font-weight: bold;
  font-size: 11px;
  margin: 0;
  padding: 0;
}

.ForoIcon__text {
  font-size: 10px;
  text-align: center;
  margin: 0;
  padding: 0;
}

.ForoIcon__svg {
  height: 20px;
}

.ForoIcon__container {
  margin: 2px;
  width: auto !important;
}

/* Conversaciones */

.ForoIconConversaciones__cantidad {
  font-weight: bold;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.ForoIconConversaciones__text {
  font-size: 14px;
  text-align: center;
  margin: 0;
  padding: 0;
}

.ForoIconConversaciones__svg {
  height: 33px;
}

.ForoIconConversaciones__container {
  margin: 1px;
  width: auto !important;
}

/* Post */

.ForoIconPost__cantidad {
  font-weight: bold;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.ForoIconPost__text {
  font-size: 12px;
  text-align: center;
  margin: 0;
  padding: 0;
}

.ForoIconPost__svg {
  height: 33px;
}

.ForoIconPost__container {
  margin: 5px;
  width: auto !important;
}