.post__comentario__btns_hijonivel2 {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: -webkit-fill-available;
  margin-left: 0px;
  /* flex-grow: 1; */
}

.fecha_comentario_hijonivel2 {
  display: flex;
  padding: 0;
  margin: 0px;
  font-size: 15px;
  font-weight: 450;
}

.cuadradoo {
  position: absolute;
  border-bottom-left-radius: 10px;
  left: 30px;
  height: 20px;
  width: 24px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-top-color: black;
  border-right-color: black;
  border-left-style: solid;
  border-left-color: black;
  border-bottom-color: black;
  border-left-width: 2px;
  display: block;
  text-align: -webkit-match-parent;
  -webkit-font-smoothing: antialiased;
  font-size:12px;
  direction: ltr;
  line-height: 1.34;
}

.recta {
  width: 70px;
  height: 35px;
  
}


/* @media (min-width: 0px) and (max-width: 350px) {

} */