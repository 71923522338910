/* Buscador */
.buscador__container {
  text-align: left;
  width: auto;
  height: fit-content;
  background-color: transparent;
  padding-left: calc(5% + 20px);
  padding-right: calc(5% + 20px);
  display: flex;
  align-items: left;
}
.resultados__padding {
  display: flex;
  flex-direction: column;
  width: auto;
  height: fit-content;
  text-align: left;
  background-color: transparent;
  padding-left: calc(5% + 20px);
  padding-right: calc(5% + 20px);
  padding-top: 15px;
}
.buscador__titulo {
  width: auto;
  font-size: 15px;
  letter-spacing: 0.7px;
}
.buscador__buscador {
  width: 100%;
}
.buscador__botonBuscar {
  width: 100%;
}
.buscador__botonBuscarContainer {
  display: flex;
  justify-content: flex-start;
  margin-left: 0px;
}
.buscador__area {
  width: 100%;
}
.buscador__divider {
  margin-top: 20px;
  margin-bottom: 20px;
}

.boton__subirContainer {
  display: flex;
  justify-content: flex-end;
  padding-left: calc(5% + 20px);
  padding-right: calc(5% + 20px);
  height: 0px;
}
.boton__instructivo {
  height: 35px;
  width: 120px;
  z-index: 1;
  transform: translateY(-30px);
  margin-right: 10px !important;
}

.boton__subirMaterial {
  height: 35px;
  width: 120px;
  z-index: 1;
  transform: translateY(-30px);
}
.boton__subirMaterialIcon {
  padding: 2%;
}
.resultados__titulo {
  font-size: 15px;
  letter-spacing: 0.7px;
  font-weight: bolder;
}

.material__docente {
  display: grid;
  grid-template-columns: repeat(auto-fill, 250px);
  grid-gap: 12px 12px;
  justify-content: space-between;
}

/* Paginamiento */
.pagination__center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 30px 0;
  width: inherit;
}

.paginamiento {
  align-self: center;
}

@media (max-width: 760px) {
  .boton__subirMaterial {
    transform: none;
  }
}
