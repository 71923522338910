.root {
  margin: 0;
  padding-left: calc(5% + 20px);
  padding-right: calc(5% + 20px);
  max-width: 100%;
}
.container {
  margin: auto;
  margin-top: 30px;
  background: #f4f6f7;
}

.label {
  width: 100px;
}

.button__Container {
  display: flex;
  justify-content: space-around;
  margin: 20px;
}

.form_manuales {
  margin: 50px 0;
}

.dropzone_manuales {
  width: 70%;
  margin: 30px auto;
}

.input {
  width: 47%;
  display: flex;
  flex-direction: column;
}
.error {
  text-align: center;
  color: red;
}

.root.loading {
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media (max-width: 700px) {
  .input {
    width: 100%;
  }
}
