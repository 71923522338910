/*  */
.Capsula__detalle {
  width: 100%;
  height: fit-content;
  margin: auto;
}
/*  */
.Capsula__detalle__container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  margin-top: 20px !important;
}

.Capsula__detalle__userIcon {
  font-size: 50px !important;
}

/*  */
.Capsula__detalle__titulo {
  align-self: flex-start !important;
  font-weight: 500 !important;
}

.Capsula__fechaBotones {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
/*  */
.Capsula__detalle__nvistas {
  color: #6a6666 !important;
  font-weight: 500;
  align-self: flex-start;
  height: 100%;
}

.visitas__container {
  display: flex;
  justify-content: flex-start;
  flex-basis: auto !important;
  align-self: flex-start;
}

.autor__container {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.descripcion__container {
  font-size: 16px !important;
  font-weight: 400 !important;
  text-align: justify;
  text-justify: inter-word;
}

.Capsula__detalle_likeButton {
  display: flex;
  font-weight: 600 !important;
  color: gray !important;
  font-size: 16px !important;
  width: 100%;
  text-align: center;
}

.Capsula__detalle__downloadButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

/* Like y comentarios */
.capsula__spaceBetween {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.capsula__center {
  display: flex;
  justify-content: center;
  height: 100%;
}

.capsula__ChatIcon {
  display: flex;
  justify-content: flex-end;
  color: #ff8168;
  margin-right: 20% !important;
  flex-basis: auto !important;
}
.capsula__ChatIconLabel {
  display: flex;
  align-items: center;
  color: #ff8168;
  flex-basis: auto !important;
}

.capsula__LikeIcon {
  display: flex;
  justify-content: flex-start;
  /* margin: auto !important; */
  margin-right: 20% !important;
  flex-basis: auto !important;
}
.capsula__LikeIconLabel {
  display: flex;
  flex-basis: auto !important;
}

.Container__padding {
  text-align: left;
  width: auto;
  height: fit-content;
  background-color: transparent;
  padding-left: calc(5% + 20px);
  padding-right: calc(5% + 20px);
  display: flex;
  flex-direction: column;
  margin: 0px 0px;
}

.Capsula__detalle__video_container {
  margin: auto;
}

.boton__subirContainerC {
  display: flex;
  justify-content: flex-end;
  height: auto;
  margin-bottom: 30px;
}
.boton__subirMaterialC {
  margin-left: 10px !important;
  height: 35px;
  width: 120px;
}
.boton__subirMaterialIconC {
  padding: 2%;
}

.Comentarios_capsula {
  margin: auto;
  width: 100%;
}
/* xs */
@media (min-width: 0px) and (max-width: 600px) {
  .tags {
    font-size: 12px;
    font-weight: 600;
  }

  .tagContainer {
    justify-content: center;
  }
}

/* sm */
@media (min-width: 600px) and (max-width: 690px) {
  .tags {
    font-size: 14px;
    font-weight: 600;
  }

  .tagContainer {
    justify-content: center;
  }
}

/* md */
@media (min-width: 900px) {
  .Capsula__detalle {
    width: 80%;
  }
  .Comentarios_capsula {
    width: 80%;
  }
  .tags {
    font-size: 16px;
    font-weight: 600;
  }

  .tagContainer {
    justify-content: flex-end;
  }

  .boton__subirMaterialC {
    z-index: 1;
    transform: translateY(-60px);
  }

  .boton__subirContainerC {
    height: 0;
  }
}
