.EditarFoto__account_image {
  object-fit: cover;
  object-position: center;
  height: 200px !important;
  width: 200px !important;
  border-radius: 50%;
}

.EditarFoto__grid_item {
  display: flex;
  justify-content: center;
}

.EditarFoto__iconAccount {
  font-size: "140px";
}
