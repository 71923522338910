.Perfil__colegios_link {
  color: #267ebf;
  text-decoration: underline;
}

.Perfil__list {
  display: flex;
  flex-direction: column;
}

.Perfil__root {
  margin: 0;
  padding: 0;
  max-width: 100%;
}
.Perfil__container {
  width: 90%;
  padding: 20px;
}
.Perfil__box {
  height: 20%;
  display: flex;
  flex-wrap: wrap;
}

.Perfil__boxBigger {
  height: auto;
}

.Perfil__imageSection {
  display: flex;
  justify-content: center;
  width: 20%;
  text-align: center;
}
.Perfil__iconAccount {
  font-size: 100px !important;
}

.Perfil__imageAccount {
  object-fit: cover;
  object-position: center;
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.Perfil__textInfo {
  display: flex;
  font-size: 12px;
  margin: 10px 0px;
  align-items: center;
}
.Perfil__Info {
  text-align: left;
  width: 60%;
}
.Perfil__button {
  margin: 10px !important;
}
.Perfil__icon {
  margin: 0 15px 0 10px;
  transform: scale(1);
}
.Perfil__row_button {
  display: flex;
  justify-content: flex-end;
  align-items: start;
}
.Perfil__Slider_manuales {
  width: 100%;
  background: #c2c9cd;
  margin: 10px 0 10px 0;
  height: 100%;
  text-align: left;
  padding: 10px;
}
.Perfil__Slider_capsulas {
  background: #c2c9cd;
  width: 100%;
  margin: 10px 0 10px 0;
  height: 100%;
  text-align: left;
  padding: 10px;
}
.Perfil__section {
  height: 50%;
}
.Perfil__customSlider {
  width: 90%;
  height: 100%;
  margin: auto;
}

.Perfil__fullWidth {
  width: 100%;
}
