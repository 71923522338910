.Comunidad__container {
  text-align: left;
  width: auto;
  height: fit-content;
  background-color: transparent;
  padding-left: calc(5% + 20px);
  padding-right: calc(5% + 20px);
  display: flex;
}

.Comunidad__Resultados__padding {
  display: flex;
  flex-direction: column;
  width: auto;
  height: fit-content;
  text-align: left;
  background-color: transparent;
  padding-left: calc(5% + 20px);
  padding-right: calc(5% + 20px);
  padding-top: 15px;
}

.profesores {
  display: grid;
  grid-gap: 12px 12px;
  justify-content: space-between;
  align-items: start;
}
.Buscador__titulo {
  width: auto;
  font-size: 15px;
}
.Buscador__buscador {
  width: 100%;
}
.Buscador__botonBuscar {
  padding: 7px;
  width: 140px;
}
.Buscador__botonBuscarContainer {
  display: flex;
  justify-content: flex-start;
  margin-left: 0px;
}
.Buscador__area {
  width: 100%;
}
.Buscador__divider {
  margin-top: 20px;
}

/* Paginamiento */
.pagination__center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 30px 0;
  width: inherit;
}
.Container_resultados__pagina {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.resultados__pagina {
  margin-left: 10px !important;
}

.paginamiento {
  align-self: center;
}

@media (min-width: 0px) and (max-width: 900px) {
  .profesores {
    grid-template-columns: repeat(auto-fill, 100%);
  }
}

@media (min-width: 900px) and (max-width: 1600px) {
  .profesores {
    grid-template-columns: repeat(auto-fill, calc(50% - 6px));
  }
}

@media (min-width: 1600px) {
  .profesores {
    grid-template-columns: repeat(auto-fill, calc(33.3% - 12px));
  }
}
