/*              COMUNIDAD DOCENTE     */
.usuario__box {
  height: inherit;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.03) !important;
  border-radius: 5px;
  background-size: cover;
  margin-bottom: 20px !important;
  padding: 10px 20px;
}

.usuario__box:hover {
  cursor: pointer;
}

.usuario__imageSection {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.33%;
  text-align: center;
}

.usuario__iconAccount {
  font-size: 140px !important;
}

.usuario__imageAccount {
  object-fit: cover;
  object-position: center;
  height: 140px;
  width: 140px;
  border-radius: 50%;
}

.usuario__textInfo {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.usuario__nombre {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  border-bottom: 1px solid black;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.usuario__userInfo {
  width: 66.6%;
  /* padding: 0 30px !important; */
  text-align: left;
  padding-right: 10px !important;
}

.usuario__icon {
  margin: 0 15px 0 0px;
  transform: scale(1);
}

.contact {
  width: 90px;
  height: 27px;
  display: flex;
  justify-content: space-between;
}

.usuario__button {
  width: 130px;
  margin: 10px !important;
}

.usuario__button.contactar {
  display: flex;
  justify-content: space-between;
}

.usuario__buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px !important;
  justify-content: space-around;
}

.skeleton__profesor {
  height: 200px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.03) !important;
  border-radius: 5px;
}

@media (max-width: 450px) {
  .usuario__imageAccount {
    font-size: 70px !important;
  }

  .skeleton__profesor {
    height: 300px;
  }

  .usuario__userInfo {
    padding: 10px !important;
  }
}

@media (max-width: 600px) {
  .usuario__buttons {
    flex-direction: column;
  }
}