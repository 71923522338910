footer {
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px;
  border-top: 3px ridge #267EBF;
}

.footer_text {
  color: white !important;
}