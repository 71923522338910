/*              MANUALES     */
.center {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 30px 0;
}
.manuales {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.Buscador__divider {
  margin-top: 20px;
}

.Container_resultados__pagina {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.resultados__pagina {
  margin-left: 10px !important;
}

@media (max-width: 760px) {
  .Boton__subirManual {
    transform: none;
  }
}

/**/
.boton__subirContainer {
  display: flex;
  justify-content: flex-end;
  padding-left: calc(5% + 20px);
  padding-right: calc(5% + 20px);
  height: 0px;
}

.boton__subirManual {
  height: 35px;
  width: 120px;
  z-index: 1;
  transform: translateY(-30px);
}

.Boton__subirManualIcon {
  padding: 2%;
}

.buscador__container {
  text-align: left;
  width: auto;
  height: fit-content;
  background-color: transparent;
  padding-left: calc(5% + 20px);
  padding-right: calc(5% + 20px);
  display: flex;
}

.buscador__titulo {
  width: auto;
  font-size: 15px;
}

.buscador__buscador {
  width: 100%;
}

.buscador__area {
  width: 100%;
}

.buscador__botonBuscarContainer {
  display: flex;
  justify-content: flex-start;
  margin-left: 0px;
}

.buscador__botonBuscar {
  width: 100%;
}

.buscador__divider {
  margin-top: 20px;
  margin-bottom: 20px;
}

.resultados__titulo {
  font-size: 15px;
  letter-spacing: 0.7px;
  font-weight: bolder;
}

/* Paginamiento */
.pagination__center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 30px 0;
  width: inherit;
}

.paginamiento {
  align-self: center;
}

.capsulas__resultados {
  display: flex;
  flex-direction: column;
  width: auto;
  height: fit-content;
  text-align: left;
  background-color: transparent;
  padding-left: calc(5% + 20px);
  padding-right: calc(5% + 20px);
  padding-top: 15px;
}

.manualresultados__resultadoscontainer {
  display: grid;
  grid-gap: 12px 12px;
  justify-content: space-between;
  align-items: start;
}

@media (min-width: 0px) and (max-width: 900px) {
  .manualresultados__resultadoscontainer {
    grid-template-columns: repeat(auto-fill, 100%);
  }
}

@media (min-width: 900px) and (max-width: 1600px) {
  .manualresultados__resultadoscontainer {
    grid-template-columns: repeat(auto-fill, calc(50% - 6px));
  }
}

@media (min-width: 1600px) {
  .manualresultados__resultadoscontainer {
    grid-template-columns: repeat(auto-fill, calc(33.3% - 12px));
  }
}
